/* eslint-disable import/no-duplicates */
import React from 'react'
import styled, { css } from 'styled-components'

import { BoxProps } from '@material-ui/core/Box'
import { Box } from 'src/adaptor/materialUI'

import Img from 'src/components/dataDisplay/Img'
import transformColor from 'src/utils/transformColor'
import { ProfileLinkType } from 'src/generated/graphql'

// Navigation URL Type Icons
import chevron from 'src/assets/images/icons/chevron.svg'
import chevronLarge from 'src/assets/images/icons/chevronLarge.svg'
import chevronDown from 'src/assets/images/icons/chevronDown.svg'
import externalLink from 'src/assets/images/icons/externalLink.svg'
import externalLinkBlack from 'src/assets/images/icons/externalLinkBlack.svg'
import externalLinkContrast from 'src/assets/images/icons/externalLinkContrast.svg'
import externalLinkWhite from 'src/assets/images/icons/externalLinkWhite.svg'
import externalLinkGold from 'src/assets/images/icons/externalLinkGold.svg'
import lesMillsWhite from 'src/assets/images/icons/lesMillsWhite.svg'
import lesMillsBlack from 'src/assets/images/icons/lesMillsBlack.svg'
// Notification Icons
import notificationMusic from 'src/assets/images/icons/notification-music.svg'
import notificationProfile from 'src/assets/images/icons/notification-profile.svg'
import notificationCalendar from 'src/assets/images/icons/notification-calendar.svg'
// List Icons
import listLoader from 'src/assets/images/icons/list-loader.svg'
// Utility Icons
import alarm from 'src/assets/images/icons/alarm.svg'
import edit from 'src/assets/images/icons/edit.svg'
import { ReactComponent as EditSvg } from 'src/assets/images/icons/edit.svg'
import camera, {
  ReactComponent as CameraSvg,
} from 'src/assets/images/icons/camera.svg'
import mapPin from 'src/assets/images/icons/mapPin.svg'
import darkMapPin from 'src/assets/images/icons/darkMapPin.svg'
import whiteMapPin from 'src/assets/images/icons/whiteMapPin.svg'
import view from 'src/assets/images/icons/view.svg'
import encircledAdd from 'src/assets/images/icons/encircledAddColored.svg'
import encircledTick from 'src/assets/images/icons/encircledTick.svg'
import encircledGreenTick from 'src/assets/images/icons/encircledGreenTick.svg'
import filledGreenTick from 'src/assets/images/icons/filledGreenTick.svg'
import filledTick from 'src/assets/images/icons/filledTick.svg'
import email from 'src/assets/images/icons/email.svg'
import search from 'src/assets/images/icons/search.svg'
import lock from 'src/assets/images/icons/lock.svg'
import phone from 'src/assets/images/icons/phone.svg'
import message from 'src/assets/images/icons/message.svg'
import levelMedal from 'src/assets/images/icons/levelMedal.png'
import refresh from 'src/assets/images/icons/refresh.svg'
import load from 'src/assets/images/icons/load.svg'
import strengthBadge from 'src/assets/images/icons/strengthBadge.svg'
import exportIcon from 'src/assets/images/icons/exportIcon.svg'
import print from 'src/assets/images/icons/print.svg'
import success from 'src/assets/images/icons/success.svg'
import error from 'src/assets/images/icons/error.svg'
import warning from 'src/assets/images/icons/warn.svg'
import redWarning from 'src/assets/images/icons/red-warn.svg'
import chat from 'src/assets/images/icons/chat.svg'
import goldentick from 'src/assets/images/icons/goldentick.svg'
import heartBeat from 'src/assets/images/icons/heartFilled.svg'
import emptyCircle from 'src/assets/images/icons/emptyCircle.svg'
import encircledInfo from 'src/assets/images/icons/encircledInfo.svg'
import redMinus from 'src/assets/images/icons/redMinus.svg'
import whiteCircle from 'src/assets/images/icons/whiteCircle.svg'
import downloadCloudGold from 'src/assets/images/icons/downloadCloudGold.svg'
import downloadCloudBlack from 'src/assets/images/icons/downloadCloudBlack.svg'
import upload from 'src/assets/images/icons/upload.svg'
import whiteUpload from 'src/assets/images/icons/upload-white.svg'
import whitePlay from 'src/assets/images/icons/play-white.svg'
import encircledWarning from 'src/assets/images/icons/encircledWarning.svg'
import unavailable from 'src/assets/images/icons/unavailable.svg'

// Social Media Icons
import facebook from 'src/assets/images/icons/socialMedia/facebook.svg'
import instagram from 'src/assets/images/icons/socialMedia/instagram.svg'
import youtube from 'src/assets/images/icons/socialMedia/youtube.svg'
import url from 'src/assets/images/icons/socialMedia/url.svg'
import tiktok from 'src/assets/images/icons/socialMedia/tiktok.svg'

// Program Logo types
import BODYATTACK from 'src/assets/images/programLogos/BODYATTACK.png'
import BODYBALANCE from 'src/assets/images/programLogos/BODYBALANCE.png'
import BODYCOMBAT from 'src/assets/images/programLogos/BODYCOMBAT.png'
import BODYFLOW from 'src/assets/images/programLogos/BODYFLOW.png'
import BODYJAM from 'src/assets/images/programLogos/BODYJAM.png'
import BODYPUMP from 'src/assets/images/programLogos/BODYPUMP.png'
import BODYSTEP from 'src/assets/images/programLogos/BODYSTEP.png'
import LMISTEP from 'src/assets/images/programLogos/LMISTEP.png'
import RPM from 'src/assets/images/programLogos/RPM.png'
import SPRINT from 'src/assets/images/programLogos/SPRINT.png'
import SHBAM from 'src/assets/images/programLogos/SHBAM.png'
import BARRE from 'src/assets/images/programLogos/BARRE.png'
import CORE from 'src/assets/images/programLogos/CORE.png'
import TONE from 'src/assets/images/programLogos/TONE.png'
import GRIT from 'src/assets/images/programLogos/GRIT.png'
import TRIP from 'src/assets/images/programLogos/TRIP.png'
import BTMEARLYYEARS from 'src/assets/images/programLogos/BTMEARLYYEARS.png'
import BTMSCHOOLYEARS from 'src/assets/images/programLogos/BTMSCHOOLYEARS.png'
import DANCE from 'src/assets/images/programLogos/DANCE.png'
import STRENGTHDEVELOPMENT from 'src/assets/images/programLogos/STRENGTHDEVELOPMENT.png'
import LESMILLSSHAPES from 'src/assets/images/programLogos/LESMILLSSHAPES.png'

import { ReactComponent as NoResultSvg } from 'src/assets/images/no-results.svg'
import { ReactComponent as NoEnquiryResult } from 'src/assets/images/enquiry-no-results.svg'
import { ReactComponent as InstructorCardSkeletonSvg } from 'src/assets/images/instructor-card-skeleton.svg'

import whiteHouse from 'src/assets/images/icons/whiteHouse.svg'
import darkHouse from 'src/assets/images/icons/darkHouse.svg'

export enum LinkTypeIcon {
  Chevron = 'CHEVRON',
  ChevronLarge = 'CHEVRON_LARGE',
  ChevronDown = 'CHEVRON_DOWN',
  ExternalLink = 'EXTERNAL_LINK',
  ExternalLinkBlack = 'EXTERNAL_LINK_BLACK',
  ExternalLinkContrast = 'EXTERNAL_LINK_CONTRAST',
  ExternalLinkWhite = 'EXTERNAL_LINK_WHITE',
  ExternalLinkGold = 'EXTERNAL_LINK_GOLD',
}

export enum LogoIcon {
  LesMillsWhite = 'LES_MILLS_WHITE',
  LesMillsBlack = 'LES_MILLS_BLACK',
}

export enum NotificationIcon {
  Release = 'NOTIFICATION_RELEASE',
  Position = 'NOTIFICATION_POSITION',
  Plans = 'NOTIFICATION_PLANS',
}

export enum ListIcon {
  Loader = 'LOADER',
}

export enum UtilityIcon {
  Alarm = 'Alarm',
  Edit = 'EDIT',
  Camera = 'CAMERA',
  MapPin = 'MAP_PIN',
  View = 'VIEW',
  Add = 'ADD',
  Tick = 'TICK',
  Email = 'EMAIL',
  Search = 'SEARCH',
  FilledTick = 'FILLED_TICK',
  Lock = 'LOCK',
  Phone = 'PHONE',
  Message = 'MESSAGE',
  DarkMapPin = 'DARK_MAP_PIN',
  WhiteMapPin = 'WHITE_MAP_PIN',
  LevelMedal = 'LEVEL_MEDAL',
  Refresh = 'REFRESH',
  Load = 'LOAD',
  StrengthBadge = 'STRENGTH_BADGE',
  Export = 'EXPORT',
  Print = 'PRINT',
  Error = 'ERROR',
  Warning = 'WARNING',
  RedWarning = 'RED_WARNING',
  Success = 'Success',
  Chat = 'Chat',
  GoldTick = 'GOLD_TICK',
  GreenTick = 'GREEN_TICK',
  FilledGreenTick = 'FILLED_GREEN_TICK',
  HeartBeat = 'PROGRAMS',
  EmptyCircle = 'EMPTY_CIRCLE',
  EncircledInfo = 'ENCIRCLED_INFO',
  WhiteHouse = 'WHITE_HOUSE',
  DarkHouse = 'DARK_HOUSE',
  RedMinus = 'RED_MINUS',
  WhiteCircle = 'WHITE_CIRCLE',
  DownloadCloudGold = 'DOWNLOAD_CLOUD_GOLD',
  DownloadCloudBlack = 'DOWNLOAD_CLOUD_BLACK',
  Upload = 'UPLOAD',
  WhiteUpload = 'WHITE_UPLOAD',
  WhitePlay = 'WHITE_PLAY',
  EncircledWarning = 'ENCIRCLED_WARNING',
  Unavailable = 'UNAVAILABLE',
}

export enum SocialMediaIcon {
  Facebook = 'FACEBOOK',
  Youtube = 'YOUTUBE',
  Instagram = 'INSTAGRAM',
  Tiktok = 'TIKTOK',
  PersonalWebsite = 'PERSONAL_WEBSITE',
}

export enum ProgramLogoType {
  BA = 'BODYATTACK',
  BB = 'BODYBALANCE',
  BC = 'BODYCOMBAT',
  BF = 'BODYFLOW',
  BJ = 'BODYJAM',
  BP = 'BODYPUMP',
  BS = 'BODYSTEP',
  LS = 'LMISTEP',
  RP = 'RPM',
  SP = 'SPRINT',
  SB = 'SHBAM',
  BR = 'BARRE',
  CR = 'CORE',
  TN = 'TONE',
  GR = 'GRIT',
  TR = 'TRIP',
  BME = 'BORN_TO_MOVE_EARLY_YEARS',
  BMS = 'BORN_TO_MOVE_SCHOOL_YEARS',
  DN = 'DANCE',
  SD = 'STRENGTH_DEVELOPMENT',
  SH = 'LES_MILLS_SHAPES',
}

export type IconTypes =
  | LinkTypeIcon
  | LogoIcon
  | NotificationIcon
  | ListIcon
  | UtilityIcon
  | ProfileLinkType
  | ProgramLogoType

export interface IllustrationProps extends BoxProps {
  type: IconTypes
}

export interface IllustrationWithSrcProps extends BoxProps {
  src: string
  alt: string
}

interface IllustrationImg {
  src: string
  alt: string
}

const illustrations: {
  [k in IconTypes]: IllustrationImg
} = {
  // Link Type Icons
  [LinkTypeIcon.Chevron]: { src: chevron, alt: 'Internal Link' },
  [LinkTypeIcon.ChevronLarge]: { src: chevronLarge, alt: 'Internal Link' },
  [LinkTypeIcon.ChevronDown]: { src: chevronDown, alt: 'Open' },
  [LinkTypeIcon.ExternalLink]: { src: externalLink, alt: 'External Link' },
  [LinkTypeIcon.ExternalLinkBlack]: {
    src: externalLinkBlack,
    alt: 'External Link',
  },
  [LinkTypeIcon.ExternalLinkContrast]: {
    src: externalLinkContrast,
    alt: 'External Link',
  },
  [LinkTypeIcon.ExternalLinkWhite]: {
    src: externalLinkWhite,
    alt: 'External Link',
  },
  [LinkTypeIcon.ExternalLinkGold]: {
    src: externalLinkGold,
    alt: 'External Link',
  },

  // Notification Icons
  [NotificationIcon.Plans]: { src: notificationCalendar, alt: 'Plans' },
  [NotificationIcon.Release]: { src: notificationMusic, alt: 'Release' },
  [NotificationIcon.Position]: { src: notificationProfile, alt: 'Position' },

  // Logo Icons
  [LogoIcon.LesMillsWhite]: {
    src: lesMillsWhite,
    alt: 'Les Mills Digital Hub',
  },
  [LogoIcon.LesMillsBlack]: {
    src: lesMillsBlack,
    alt: 'Les Mills Digital Hub',
  },
  // List Icons
  [ListIcon.Loader]: { src: listLoader, alt: 'Loading' },
  // Utility Icons
  [UtilityIcon.Alarm]: { src: alarm, alt: 'Alarm' },
  [UtilityIcon.Edit]: { src: edit, alt: 'Edit' },
  [UtilityIcon.Email]: { src: email, alt: 'Email' },
  [UtilityIcon.Camera]: { src: camera, alt: 'Photo' },
  [UtilityIcon.MapPin]: { src: mapPin, alt: 'Map Pin' },
  [UtilityIcon.View]: { src: view, alt: 'View' },
  [UtilityIcon.Search]: { src: search, alt: 'Search' },
  [UtilityIcon.Add]: { src: encircledAdd, alt: 'Add' },
  [UtilityIcon.Tick]: { src: encircledTick, alt: 'Tick' },
  [UtilityIcon.GreenTick]: { src: encircledGreenTick, alt: 'Green Tick' },
  [UtilityIcon.FilledGreenTick]: {
    src: filledGreenTick,
    alt: 'Filled Green Tick',
  },
  [UtilityIcon.FilledTick]: { src: filledTick, alt: 'True' },
  [UtilityIcon.Lock]: { src: lock, alt: 'Lock' },
  [UtilityIcon.Phone]: { src: phone, alt: 'Phone' },
  [UtilityIcon.Message]: { src: message, alt: 'Message' },
  [UtilityIcon.DarkMapPin]: { src: darkMapPin, alt: 'Map Pin' },
  [UtilityIcon.WhiteMapPin]: { src: whiteMapPin, alt: 'White Map Pin' },
  [UtilityIcon.WhiteHouse]: { src: whiteHouse, alt: 'White House' },
  [UtilityIcon.DarkHouse]: { src: darkHouse, alt: 'Dark House' },
  [UtilityIcon.LevelMedal]: { src: levelMedal, alt: 'Level medal' },
  [UtilityIcon.Refresh]: { src: refresh, alt: 'Refresh' },
  [UtilityIcon.Load]: { src: load, alt: 'Load' },
  [UtilityIcon.StrengthBadge]: { src: strengthBadge, alt: 'Strength Badge' },
  [UtilityIcon.Export]: { src: exportIcon, alt: 'Export' },
  [UtilityIcon.Print]: { src: print, alt: 'Print' },
  [UtilityIcon.Error]: { src: error, alt: 'Error' },
  [UtilityIcon.Warning]: { src: warning, alt: 'Warning' },
  [UtilityIcon.RedWarning]: { src: redWarning, alt: 'Red Warning' },
  [UtilityIcon.Success]: { src: success, alt: 'Success' },
  [UtilityIcon.Chat]: { src: chat, alt: 'Chat' },
  [UtilityIcon.GoldTick]: { src: goldentick, alt: 'Tick' },
  [UtilityIcon.HeartBeat]: { src: heartBeat, alt: 'HeartBeat' },
  [UtilityIcon.EmptyCircle]: { src: emptyCircle, alt: 'Empty circle' },
  [UtilityIcon.EncircledInfo]: {
    src: encircledInfo,
    alt: 'Encircled info',
  },
  [UtilityIcon.RedMinus]: {
    src: redMinus,
    alt: 'Red minus',
  },
  [UtilityIcon.WhiteCircle]: { src: whiteCircle, alt: 'White Circle' },
  [UtilityIcon.DownloadCloudGold]: {
    src: downloadCloudGold,
    alt: 'Download from cloud in gold',
  },
  [UtilityIcon.DownloadCloudBlack]: {
    src: downloadCloudBlack,
    alt: 'Download from cloud',
  },
  [UtilityIcon.Upload]: { src: upload, alt: 'Upload' },
  [UtilityIcon.WhiteUpload]: { src: whiteUpload, alt: 'Upload' },
  [UtilityIcon.WhitePlay]: { src: whitePlay, alt: 'play video' },
  [UtilityIcon.EncircledWarning]: { src: encircledWarning, alt: 'Warning' },
  [UtilityIcon.Unavailable]: { src: unavailable, alt: 'Unavailable' },

  // SocialMedia Icons
  [ProfileLinkType.Tiktok]: { src: tiktok, alt: 'tiktok' },
  [ProfileLinkType.PersonalWebsite]: { src: url, alt: 'url' },
  [ProfileLinkType.Youtube]: { src: youtube, alt: 'youtube ' },
  [ProfileLinkType.Facebook]: { src: facebook, alt: 'facebook' },
  [ProfileLinkType.Instagram]: { src: instagram, alt: 'instagram' },
  // Program Icons
  [ProgramLogoType.BA]: { src: BODYATTACK, alt: 'BODYATTACK' },
  [ProgramLogoType.BB]: { src: BODYBALANCE, alt: 'BODYBALANCE' },
  [ProgramLogoType.BC]: { src: BODYCOMBAT, alt: 'BODYCOMBAT' },
  [ProgramLogoType.BF]: { src: BODYFLOW, alt: 'BODYFLOW' },
  [ProgramLogoType.BJ]: { src: BODYJAM, alt: 'BODYJAM' },
  [ProgramLogoType.BP]: { src: BODYPUMP, alt: 'BODYPUMP' },
  [ProgramLogoType.BS]: { src: BODYSTEP, alt: 'BODYSTEP' },
  [ProgramLogoType.LS]: { src: LMISTEP, alt: 'LMISTEP' },
  [ProgramLogoType.RP]: { src: RPM, alt: 'RPM' },
  [ProgramLogoType.SP]: { src: SPRINT, alt: 'LES MILLS SPRINT' },
  [ProgramLogoType.SB]: { src: SHBAM, alt: 'SHBAM' },
  [ProgramLogoType.BR]: { src: BARRE, alt: 'Les Mills Barre' },
  [ProgramLogoType.CR]: { src: CORE, alt: 'Les Mills Core' },
  [ProgramLogoType.TN]: { src: TONE, alt: 'Les Mills Tone' },
  [ProgramLogoType.GR]: { src: GRIT, alt: 'Les Mills Grit' },
  [ProgramLogoType.TR]: { src: TRIP, alt: 'The Trip' },
  [ProgramLogoType.BME]: {
    src: BTMEARLYYEARS,
    alt: 'BORN TO MOVE - EARLY YEARS',
  },
  [ProgramLogoType.BMS]: {
    src: BTMSCHOOLYEARS,
    alt: 'BORN TO MOVE - SCHOOL YEARS',
  },
  [ProgramLogoType.DN]: {
    src: DANCE,
    alt: 'Les Mills Dance',
  },
  [ProgramLogoType.SD]: {
    src: STRENGTHDEVELOPMENT,
    alt: 'Strength Development',
  },
  [ProgramLogoType.SH]: {
    src: LESMILLSSHAPES,
    alt: 'Les Mills Shapes',
  },
}
/* If you want to customise svgs like colour and height as an svg component 
you can use the SvgIllustrations.tsx <SvgIllustrations/> component instead */

export const Illustration: React.FC<IllustrationProps> = ({
  type,
  ...props
}) => (
  <Box {...props}>
    <Img {...illustrations[type]} />
  </Box>
)

export const IllustrationWithSrc: React.FC<IllustrationWithSrcProps> = ({
  src,
  alt,
  ...props
}) => (
  <Box {...props}>
    <Img src={src} alt={alt} />
  </Box>
)

const EditSvgColored = styled(EditSvg)<{ $color?: string }>(
  ({ $color, theme }) =>
    $color
      ? css`
          path {
            fill: ${transformColor($color, theme)};
          }
        `
      : ''
)
const CameraSvgColored = styled(CameraSvg)<{ $color?: string }>(
  ({ $color, theme }) =>
    $color
      ? css`
          path {
            fill: ${transformColor($color, theme)};
          }
        `
      : ''
)
interface SvgIconProps {
  color?: string
}

export const EditIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
  <EditSvgColored {...props} $color={color} />
)

export const NoResults: React.FC = props => (
  <NoResultSvg width="272px" {...props} />
)

export const NoEnquiryResults: React.FC = props => (
  <NoEnquiryResult width="352px" {...props} />
)

export const InstructorCardSkeleton: React.FC = props => (
  <InstructorCardSkeletonSvg width="272px" {...props} />
)

export const CameraIcon: React.FC<SvgIconProps> = ({ color, ...props }) => (
  <CameraSvgColored
    fontSize={22}
    width={22}
    height={22}
    fill="none"
    {...props}
    $color={color}
  />
)

export const getIllustrationIconSrc = (type: IconTypes) => {
  return illustrations[type].src
}

export default Illustration
